import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Route, Router } from "@angular/router";
import { AuthenticationService } from '../../shared/services/auth/authentication.service';
import { FilterHeaderComponent } from '../../pages/filter-header/filter-header.component';
import { DataService } from '../../shared/services/data/data.service';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [FilterHeaderComponent],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss',
})
export class HeaderComponent implements OnInit {
  public activeRoute: string = 'dashboard';
  public isOpen: boolean = false;
  public isFullScreenMode: boolean = false;
  private currentURL: string = '';

  public sideNav: boolean = true;
  public liveConsole: boolean = true;
  public desktopMode: string = 'cockpit';

  constructor(
    private route: Router,
    private activateRoute: ActivatedRoute,
    public authenticationService: AuthenticationService,
    public dataService: DataService
  ) {}

  ngOnInit(): void {}

  public handlepopUp() {
    this.isOpen = !this.isOpen;
  }

  public toggleFullScreen() {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen();
      this.isFullScreenMode = true;
    } else if (document.exitFullscreen) {
      document.exitFullscreen();
      this.isFullScreenMode = false;
    }
  }
  public navigateLogs() {
    this.route.navigate(['./logs']);
    this.activeRoute = 'logs';
  }

  public navigateDashboard() {
    this.route.navigate(['./dashboard']);
    this.activeRoute = 'dashboard';
  }

  public toggleMode(event: string) {
    this.desktopMode = event;
    if (event === 'cockpit') {
      this.dataService.changeViewMode(true);
    }
  }
}
