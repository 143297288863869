<div class="bg-white border-gray-200 filter-header-component">
  <div class="container-fluid mx-auto flex justify-between items-center gap-16">
    <!-- Left Section -->
    <div class="flex items-center">
      <!-- <p class="font-bold text-lg">{{selectedView && selectedView.name ? selectedView.name : 'View'}} - All</p> -->
      <div class="ml-4 relative">
        <button (click)="toggleView()" class="flex w-72 gap-10 items-center justify-between bg-white border border-gray-300 rounded-md px-4 py-2 w-50">
          {{selectedView && selectedView.name ? selectedView.name : 'Select View'}}
          <img class="w-5" src="../../../assets/icons/down-arrow.svg" alt="down-arrow">
        </button>
        <!-- Dropdown Content -->
        <div (click)="isProgressView = false" [class.filter-header-component-backdrop]="isProgressView"></div>
        @if (isProgressView) {
          <div class="absolute mt-1 w-64 bg-white rounded-md shadow-lg z-20">
              <ul class="py-1">
                @for (item of progressView; track $index) {
                  <li (click)="selectView(item)" class="cursor-pointer">
                    <a class="block px-4 py-2 hover:bg-gray-100 me-1" [class.cursor-not-allowed]="item.name === 'Variance View'">
                      {{item?.name}}
                      @if (item.name === "Variance View") {
                        <span class="text-xs">(coming soon)</span>
                      }
                    </a>
                    
                  </li>
                }
                  <!-- <li>
                      <a href="#" class="px-4 py-2 hover:bg-gray-100 flex items-center justify-between">
                          Create new view
                          <span>+</span>
                      </a>
                  </li> -->
              </ul>
            </div>
        }
      </div>
    </div>
    <!-- Center Section -->
    <div class="flex items-center">
      <div class="relative">
        <input 
          type="text" 
          name="search"
          (keyup.enter)="searchProgress()"
          class="border border-gray-300 rounded-md px-4 py-2 pl-5 search-input" 
          [(ngModel)]="searchText"
          placeholder="Search Waves, Orders, Cartons">
        @if (searchText) {
          <mat-icon class="search-input-icon" (click)="clearText()">close</mat-icon>
        }
        <div class="search-input-image" (click)="searchProgress()">
          <img  src="../../../assets/icons/search_gray.svg" alt="search">
        </div>
      </div>
      <!-- <p class="font-bold relative ms-5">Filters</p>
      <div class="ml-4 relative">
          <button (click)="toggleFilterView()" class="flex items-center justify-between bg-white border border-gray-300 rounded-md px-4 py-2 w-60">
              Not released
              <div class="flex justify-center align-middle gap-1">
                <span class="text-sm text-gray-500">waves</span>
                <img class="w-5" src="../../../assets/icons/down-arrow.svg" alt="down-arrow">
              </div>
            </button>
        @if (isFilterView) {
          <div class="absolute mt-1 w-60 bg-white rounded-md shadow-lg z-10">
              <ul class="py-1">
                  <li><a href="#" class="block px-4 py-2 hover:bg-gray-100">Variance view</a></li>
                  <li><a href="#" class="block px-4 py-2 hover:bg-gray-100">Progress view</a></li>
                  <li><a href="#" class="block px-4 py-2 hover:bg-gray-100">NCDC Custom dashboard</a></li>
                  <li>
                      <a href="#" class="px-4 py-2 hover:bg-gray-100 flex items-center justify-between">
                          Create new view
                          <span>+</span>
                      </a>
                  </li>
              </ul>
            </div>
        }
      </div> -->
      <div class="ml-4 relative">
        <button 
          (click)="toogleAdvanceFilter()"
          class="flex gap-10 items-center justify-between bg-white border border-gray-300 rounded-md px-4 py-2 w-50"
          [disabled]="!isProgressViewFlag">
          Status: {{ selectedStatus | titlecase }}
          <img class="w-5" src="../../../assets/icons/down-arrow.svg" alt="down-arrow">
        </button>
        <!-- Dropdown Content -->
        <div *ngIf="advanceFilter && isProgressViewFlag" class="absolute mt-1 w-50 bg-white rounded-md shadow-lg z-10">
          <ul class="py-1">
            <li *ngFor="let item of statusList; let i = index" (click)="onStatusChange(item)" class="cursor-pointer">
              <a class="block px-4 py-2 hover:bg-gray-100 me-1">{{ item?.name | titlecase }}</a>
            </li>
          </ul>
        </div>
      </div>

    </div>
    <!-- Right Section -->
    <div class="flex items-center">
      <button 
        (click)="toggleDesktopView(this.viewType[0])"
        [disabled]="desktopView === 'Spatial View'"
        [class.view-btn-active]="desktopView === this.viewType[0]"
        class="flex gap-3 text-xs items-center view-btn bg-white border border-e-0 border-gray-300 rounded-tl-md rounded-bl-md px-4 py-2">
        <img [src]="desktopView === this.viewType[0] ? '../../../assets/icons/cockpit_white.svg' : '../../../assets/icons/cockpit_gray.svg'" alt="cockpit">
        {{this.viewType[0]}}
      </button>
      <button 
      [class.view-btn-active]="desktopView === this.viewType[1]"
      (click)="toggleDesktopView(this.viewType[1])"
        class="flex gap-3 view-btn text-xs items-center bg-white border border-gray-300 rounded-tr-md rounded-br-md px-4 py-2">
        <img [src]="desktopView !== this.viewType[1] ? '../../../assets/icons/birdseye.svg' : '../../../assets/icons/birdseye_gray.svg'" alt="bird's eye">
        {{this.viewType[1]}}
      </button>
      <!-- <button 
        (click)="toggleDesktopView('cockpit')"
        [class.view-btn-active]="desktopView === 'cockpit'"
        class="flex gap-3 items-center bg-white border border-gray-300 rounded-md px-4 py-2">
          <img src="../../../assets/icons/cockpit_white.svg" alt="cockpit">
        Cockpit
      </button> -->
    </div>
  </div>
</div>
