import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { ApiService } from '../../shared/services/api/api.service';
import { Modes, ProgressView } from './interface/filter-header-interface';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { DataService } from '../../shared/services/data/data.service';
import { NavigationEnd, Router, RouterModule } from '@angular/router';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-filter-header',
  standalone: true,
  imports: [
    MatCheckboxModule,
    MatIconModule,
    CommonModule,
    FormsModule,
    RouterModule,
  ],
  templateUrl: './filter-header.component.html',
  styleUrl: './filter-header.component.scss',
})
export class FilterHeaderComponent implements OnInit {
  public isProgressView: boolean = false;
  isProgressViewFlag: boolean = false;
  public isFilterView: boolean = false;
  public desktopView: string | undefined = 'cockpit';
  public advanceFilter: boolean = false;
  public progressView: Array<ProgressView> = [];
  public selectedView: ProgressView = {};
  public searchText: string = '';
  public routeType: any;
  public historyView: boolean = false;
  public viewType: string[] = ['Cockpit', "Birds's eye"];

  // Overall Status to load items in app
  selectedStatus: string = 'all';
  statusList: Modes[] = [
    {
      name: 'all',
    },
    {
      name: 'in progress',
    },
    {
      name: 'completed',
    },
    {
      name: 'cancelled',
    },
  ];

  @Output() currentView = new EventEmitter<string>();

  constructor(
    private apiService: ApiService,
    private dataService: DataService,
    private route: Router
  ) {}

  ngOnInit(): void {
    this.getAllProgressViews();
    this.dataService.searchProgress.subscribe((res) => {
      if (res == null) {
        this.searchText = '';
      }
    });
    this.dataService.viewChange.subscribe((change) => {
      if (change) {
        setTimeout(() => {
          this.changeView();
        }, 200);
      }
    });

    // Detect route changes to check if we are on /dashboard/progress
    this.route.events
      .pipe(
        filter(
          (event): event is NavigationEnd => event instanceof NavigationEnd
        )
      )
      .subscribe((event: NavigationEnd) => {
        this.isProgressViewFlag =
          event.urlAfterRedirects === '/dashboard/progress';
        if (!this.isProgressViewFlag) {
          this.selectedStatus = 'all'; // Reset the status to 'all' if not on progress view
        }
      });
  }

  private changeView() {
    const route = this.route.url.split('/');
    const currentRoute = route[2];
    if (currentRoute === 'divert-lane') {
      this.selectedView = this.progressView[2];
      this.desktopView = 'Cockpit';
    } else if (currentRoute === 'history-view') {
      this.selectedView = this.progressView[3];
      this.desktopView = 'Spatial View';
    } else {
      this.selectedView = this.progressView[0];
      this.desktopView = 'Cockpit';
    }
  }

  onStatusChange = (selectedItem: Modes) => {
    this.selectedStatus = selectedItem.name || 'all';
    this.dataService.setStatusMode(selectedItem.name || 'all');
    this.toogleAdvanceFilter();
  };

  public getAllProgressViews() {
    this.apiService.getAllViews().subscribe((res) => {
      if (res) {
        this.progressView = res;
        const history = { name: 'History View' };
        this.progressView.push(history);
        const router = this.route.url;
        const activeRoute = router.split('/');
        if (activeRoute[2] === 'divert-lane') {
          this.selectedView = res[2];
          this.viewType = ['Cockpit', "Birds's eye"];
          this.desktopView = 'Cockpit';
        } else if (activeRoute[2] === 'history-view') {
          this.selectedView = res[3];
          this.viewType = ['Cockpit', 'Spatial View'];
          this.desktopView = 'Spatial View';
        } else {
          this.selectedView = res[0];
          this.viewType = ['Cockpit', "Birds's eye"];
          this.desktopView = 'Cockpit';
        }
      }
    });
  }

  public selectView(view: ProgressView) {
    this.dataService.updateInfoLiveConsole(null);
    if (this.selectedView.name == view.name) {
      this.isProgressView = !this.isProgressView;
      return;
    }
    if (view.name !== 'Variance View') {
      this.selectedView = view;
    }
    console.log(view.name);
    switch (view.name) {
      case 'Progress View':
        this.route.navigate(['/dashboard/progress']);
        this.viewType = ['Cockpit', "Birds's eye"];
        this.desktopView = 'Cockpit';
        break;
      case 'Inventory View':
        this.route.navigate(['/dashboard/divert-lane']);
        this.viewType = ['Cockpit', "Birds's eye"];
        this.desktopView = 'Cockpit';
        break;
      case 'History View':
        this.route.navigate(['/dashboard/history-view']);
        this.viewType = ['Cockpit', 'Spatial View'];
        this.desktopView = 'Spatial View';
        break;
    }
    setTimeout(() => {
      this.isProgressView = !this.isProgressView;
      this.dataService.triggerViewChange(true);
    }, 500);
  }

  public toggleView() {
    this.isProgressView = !this.isProgressView;
  }

  public toggleFilterView() {
    this.isFilterView = !this.isFilterView;
  }

  public toggleDesktopView(view: string | undefined) {
    this.desktopView = view;
    if (this.desktopView !== 'Spatial View') {
      this.currentView.emit(view);
    }
  }

  public toogleAdvanceFilter() {
    if (!this.isProgressViewFlag) {
      this.advanceFilter = false;
      return;
    }
    this.advanceFilter = !this.advanceFilter;
  }

  public searchProgress() {
    const id = this.searchText.toLowerCase();
    this.dataService.updateInfoLiveConsole(null);
    this.dataService.isLoading = true;
    let type = undefined;
    this.apiService.searchProgressById(id).subscribe({
      next: (res: any) => {
        if (res.results.length > 0) {
          type = res.results[0].type;
          switch (type) {
            case 'worklist':
              this.dataService.searchProgressData({
                show: true,
                view: 'wave',
                id: { worklist_id: res.results[0].external_work_list_id },
                rerender: true,
              });
              this.route.navigate(
                [
                  `dashboard/progress/wave/${res.results[0].external_work_list_id}`,
                ],
                {
                  queryParamsHandling: 'merge',
                  skipLocationChange: false,
                }
              );
              break;
            case 'order':
              this.route.navigate(
                [
                  `dashboard/progress/order/${res.results[0].external_order_id}`,
                ],
                {
                  queryParamsHandling: 'merge',
                  skipLocationChange: false,
                }
              );
              this.dataService.searchProgressData({
                show: true,
                view: 'order',
                id: { order_id: res.results[0].external_order_id },
                rerender: true,
              });
              break;
            case 'container':
              this.route.navigate(
                [
                  `dashboard/progress/carton/${res.results[0].external_container_id}`,
                ],
                {
                  queryParamsHandling: 'merge',
                  skipLocationChange: false,
                }
              );
              this.dataService.searchProgressData({
                show: true,
                view: 'carton',
                id: { container_id: res.results[0].external_container_id },
                rerender: true,
              });
              break;
            default:
              this.dataService.toasterInfoMessage(
                'No data found with the provided ID'
              );
              break;
          }
        } else {
          this.dataService.toasterInfoMessage(
            'No data found with the provided ID'
          );
        }
        // this.dataService.setLoader(false, 'false');
      },
      error: () => {
        this.dataService.setLoader(false);
        this.dataService.toasterErrorMessage('Something went wrong!!!');
      },
    });
    // if (this.searchText.length == 6) {
    //   this.route.navigate([`dashboard/progress/wave/${this.searchText}`]);
    //   this.dataService.searchProgressData({show: true, view: 'wave', id: {worklist_id: this.searchText}, rerender: true});
    // } else if(this.searchText.length == 7) {
    //   this.route.navigate([`dashboard/progress/order/${this.searchText}`]);
    //   this.dataService.searchProgressData({show: true, view: 'order', id: {order_id: this.searchText}, rerender: true});
    // } else if(lane.startsWith('L') || lane.startsWith('l')){
    //   this.route.navigate([`dashboard/divert-lane/${this.searchText.toUpperCase()}`]);
    // } else if(this.searchText.length == 20) {
    //   this.route.navigate([`dashboard/progress/carton/${this.searchText}`]);
    //   this.dataService.searchProgressData({show: true, view: 'carton', id: {container_id: this.searchText}, rerender: true});
    // } else {
    //   this.dataService.toasterInfoMessage('No data found with the provided ID');
    // }
    this.dataService.setLoader(false);
  }

  public clearText() {
    this.searchText = '';
    this.dataService.searchProgressData({
      show: false,
      view: undefined,
      id: undefined,
    });
  }
}
